<template>
  <div id="main">
    <Banner/>
    <NavBar>
        <el-breadcrumb-item v-if="this.$route.name !== 'notFound'" style="line-height: 41px;font-size: 12px;">
          {{product.navName}}
        </el-breadcrumb-item>
    </NavBar>
    <div id="content">
        <div id="smallMenu">
          <div class="menuContent">
            <div class="menuTitle">产品中心</div>
              <ul>
                <li v-for="item in product.list" :key="item.id" @click="handleClick(item.id)">
                  {{ item.name }}
                </li>
              </ul>
          </div>
        </div>
      <div id="left">
        <div class="menuTitle">产品中心</div>
        <ul id="list">
          <li v-for="item in product.list" :ref="item.id" :key="item.id" @click="handleClick(item.id)">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <div id="right">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '../components/common/Banner'
import NavBar from '../components/common/NavBar'
export default {
  name:"product-center",
  components:{
    Banner,
    NavBar
  },
  methods:{
    handleClick(id){
      this.$router.push(`${id}`)
    },
    getNavName(nid){
      this.product.list.forEach(item => {
        if(item.id === nid){
          this.$set(this.product,'navName',item.name)
        }
      });
      this.$nextTick(()=>{
        Object.keys(this.$refs).map(key => {
          if(key === nid){
            this.$refs[key][0].id = 'active'
          }else{
            this.$refs[key][0].id = ''
          }
        })
      })
    }
  },
  data(){
    return{
      product:{
        list:[
          {id:'1',name:'第一实验室'},
          {id:'2',name:'毫米波通信'},
          {id:'3',name:'毫米波感知'},
        ],
        navName:''
      }
    }
  },
  created(){
    this.getNavName(this.$route.params?.id)
  },
  beforeRouteUpdate(to,from,next){
    this.getNavName(to.params.id)
    next()
  },
  beforeRouteLeave (to, from, next) {
    localStorage.setItem('fatherProductName',this.product.navName)
    next()
  }
}
</script>

<style scoped>
  #main{
    max-width: 1200px;
    margin: auto;
  }
  #content{
    display: flex;
    position:relative;
  }
  #list{
    list-style: none;
    margin: 0px;
    box-sizing: border-box;
    padding: 0px;
  }
  #list > li{
    padding-left: 45px;
    line-height: 45px;
    font-size: 14px;
    color:#666;
    border:1px solid #e5e5e5;
    border-top: none;
  }
 
  #list > li:hover{
    background-color: #f9f9f9;
    padding-left: 42px;
    cursor: pointer;
    border-left: 4px solid rgb(41, 137, 151);
  }
  #active{
    background-color: #f9f9f9;
    padding-left: 42px !important;
    border-left: 4px solid rgb(41, 137, 151) !important;
  }
  #left{
    width: 24%;
  }
  #right{
    width: 75%;
  }

  #smallMenu{
    background-color: #0673c7;
    background-image: url('https://pic.imgdb.cn/item/63afc12108b683016373168c.png');
    background-position:center;
    background-size:40px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    display: none;
  }
  @media (max-width:640px) {
    #left{
      display: none;
    }
    #right{
      margin: auto;
      width: 100%;
    }
    #smallMenu{
      display: block;
    }
  }
  #smallMenu ul{
    list-style: none;
    padding: 10px;
    margin: 0px;
    background: rgba(255, 255, 255, 0.9);
  }
  #smallMenu ul > li{
    line-height: 30px;
    text-align: center;
  }
  .menuContent{
    display: none;
  }
  #smallMenu:hover{
    width: 180px;
    border-radius: 0;
    height: 180px;
    background-image: none;
    background-color: rgba(242, 242, 242, 0.33);
  }
  #smallMenu:hover .menuContent{
    width: 180px;
    display: block;
  }
  .menuTitle{
    background-color:rgb(41, 137, 151);
    color: white;
    text-align: center;
    font-size: 18px;
    line-height: 50px;
  }
</style>